<template>
  <div :class="additionalCls">
    <span class="h6 pretitle d-block">
      <slot name="label"></slot>
    </span>

    <span class="h2">
      <slot name="kpi"></slot>
    </span>

    <span class="d-block">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    additionalCls: String,
  },
};
</script>
