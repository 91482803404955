import Vuex from "vuex";
import axios from "axios";
import CustomerData from "@/models/CustomerData";

const development = process.env.NODE_ENV !== "production";
const baseURL = development
  ? "http://localhost:8000/admin/kundenverwaltung/project/rest_api/project/?customer_pk="
  : "https://bms.descript.de/admin/kundenverwaltung/project/rest_api/project/?customer_pk=";

export default Vuex.createStore({
  state: {
    customerData: '',
    // TODO: use states to display appropriate messages or spinners
    isBusy: false,
    error: false,
  },
  mutations: {
    setCustomerData(state, data) {
      const customerId = data[0].customer;
      const customerName = data[0].customer_name;

      const customerData = new CustomerData(
        customerId,
        customerName,
        data
      )
      state.customerData = customerData;
    },
    setBusy(state, isBusy) {
      state.isBusy = isBusy;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async fetchCustomerProjects({ commit }, id) {
      commit("setBusy", true);

      const customerUrl = baseURL + id

      try {
        await axios
          .get(customerUrl, { withCredentials: true })
          .then(async (response) => {
            commit("setCustomerData", response.data);
            commit("setError", false);
          })
      } catch {
        commit("setError", true);
      }

      commit("setBusy", false);
    },
  },
  getters: {
    getSubProjectsById: (state) => (id) => {
      if (!state.customerData) return;
      const project = state.customerData.projects.find(project => project.id === id);
      return project.subProjects;
    }
  }
});
