export default class CustomerData {
  constructor(
    id,
    name,
    projects
  ) {
    this.id = id;
    this.name = name;

    const superProjects = projects.filter(project => project.level === 0);

    const subProjects = projects.filter(project => project.level === 1);

    superProjects.forEach((project) => {
      if (project.has_children) {
        project.subProjects = subProjects.filter(sub => sub.parent === project.id);
      }
    });

    this.projects = superProjects;
  }
}
