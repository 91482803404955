import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// bootstrap integration
import "@/assets/styles/styles.scss";
import 'bootstrap/dist/js/bootstrap.esm.js';

// apex charts for visualization
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App)

app.config.devtools = true

// Migration of filters to global properties
// following https://v3.vuejs.org/guide/migration/filters.html#global-filters
app.config.globalProperties.$filters = {
  localize(value, fractions = 0) {
    if (!value) return value;
    if (isNaN(value)) return value;
    const formatter = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: fractions,
    });
    return formatter.format(value);
  },
};

app.use(router).use(store).use(VueApexCharts).mount("#app");
