<template>
  <div class="page-header mb-6">
    <div class="container-fluid">
      <div class="page-header-body border-bottom pt-4 pb-3">
        <div class="row align-items-center">
          <div class="col-12 col-md-auto">
            <h6 class="pretitle mb-0">Kunde</h6>
            <h1 class="">{{ name }}</h1>
          </div>
          <div class="col-12 col-md-auto">
            <div class="dropdown">
              <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownRight" data-bs-toggle="dropdown" aria-expanded="false">
                Gehe zu
              </button>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownRight">
                <li><a class="dropdown-item" :href="`https://bms.descript.de/de/admin/kundenverwaltung/project/?customer=${id}`" target="_blank">Übersicht der Projekte</a></li>
                <li><a class="dropdown-item" :href="`https://bms.descript.de/de/admin/kundenverwaltung/customer/${id}/`" target="_blank">Kundestammdaten</a></li>
                <li><a class="dropdown-item" :href="`https://bms.descript.de/de/admin/kundenverwaltung/customer/${id}/leistungen`" target="_blank">Leistungen zum Kunden</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    name: String,
  }
}
</script>
