import { createRouter, createWebHashHistory } from "vue-router";
import PageNotFound from "@/views/PageNotFound.vue";
import CustomerProjects from "@/views/CustomerProjects";
import ProjectDetails from "@/views/ProjectDetails";

const routes = [
  {
    path: "/customer/:customerid",
    name: "CustomerProjects",
    component: CustomerProjects,
  },
  {
    path: "/customer/:customerid/details/:projectid",
    name: "ProjectDetails",
    component: ProjectDetails
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: PageNotFound,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
