<template>
  <div class="card card-fill">
    <div class="card-header">
      <h4 class="card-header-title mb-0">
        <slot name="header"></slot>
      </h4>
    </div>
    <div class="card-body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
