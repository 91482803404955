<template>
  <div class="container-fluid">
    <router-link :to="{ name: 'CustomerProjects', params: { customerid: customerid} }" class="mb-5" >Zurück zur Übersicht</router-link>
    <div class="row">
      <div class="col-12 col-md-4" v-for="project in $store.getters.getSubProjectsById(projectid)" :key="project.id">
        <KpiBarChart :project="project" />
      </div>
    </div>
  </div>
</template>

<script>
import KpiBarChart from "@/components/KpiBarChart";

import { useRoute } from "vue-router";

export default {
  components: {
    KpiBarChart
  },
  computed: {
    customerid() {
      const id = useRoute().params.customerid;
      return parseInt(id);
    },
    projectid() {
      const id = useRoute().params.projectid;
      return parseInt(id);
    }
  },
}
</script>
