<template>
  <div id="app">
    <div class="page-main">
      <CustomerHeader :id="$store.state.customerData.id" :name="$store.state.customerData.name" />
      <router-view />
    </div>
  </div>
</template>

<script>
import CustomerHeader from "@/components/CustomerHeader";

export default {
  components: {
    CustomerHeader
  }
};
</script>
