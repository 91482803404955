<template>
  <CustomCard>
    <template #header>
      {{ project.title }}
    </template>
    <template #body>
      <apexchart type="donut" :options="options" :series="series"></apexchart>
      <div class="row justify-content-center">
        <div class="col-5">
          <TextIndicator>
            <template #label>Stunden</template>
            <template #kpi>{{ project.used_hours }}h</template>
          </TextIndicator>
        </div>
        <div class="col-5">
          <TextIndicator>
            <template #label>Kosten</template>
            <template #kpi>{{ project.costs_total }}€</template>
          </TextIndicator>
        </div>
        <div v-if="project.has_children" class="col-10">
          <router-link :to="{ name: 'ProjectDetails', params: { customerid: project.customer, projectid: project.id } }" class="card-link">Zu den Unterprojekten</router-link>
        </div>
      </div>
    </template>
  </CustomCard>
</template>

<script>
import { defineComponent } from 'vue'
import CustomCard from '@/components/CustomCard'
import TextIndicator from '@/components/TextIndicator'

export default defineComponent({
  components: {
    CustomCard,
    TextIndicator
  },
  props: {
    project: {
      required: true,
      type: Object
    },
  },
  data () {
    return {
      options: {
        labels: ['Verbraucht', 'Verbleibend'],
        legend: {
          show: false
        },
        tooltip: {
          y: {
            formatter: function (value) {
              if (!value) return value;
              if (isNaN(value)) return value;
              const formatter = new Intl.NumberFormat("de-DE");
              return formatter.format(value) + '€';
            }
          }
        }
      },
    }
  },
  computed: {
    totalBudget() {
      let budget = 0;
      if (!this.project.has_children) {
        if (!this.project.target_hourly_rate) return 0;
        if (!this.project.estimated_hours) return 0;
        budget = parseInt(this.project.target_hourly_rate) * parseInt(this.project.estimated_hours)
      } else {
        this.project.subProjects.forEach((item) => {
          if (!item.target_hourly_rate) return 0;
          if (!item.estimated_hours) return 0;
          budget += parseInt(item.target_hourly_rate) * parseInt(item.estimated_hours);
        });
      }
      return budget;
    },
    spentBudget() {
      let budget = parseInt(this.project.costs_total);
      if (!this.project.has_children) {
        if (!this.project.target_hourly_rate) return 0;
        budget += parseInt(this.project.target_hourly_rate) * parseInt(this.project.used_hours)
      } else {
        if (!this.project.subProjects[0].target_hourly_rate) return 0;
        budget += parseInt(this.project.used_hours) * parseInt(this.project.subProjects[0].target_hourly_rate)
      }
      return budget;
    },
    series() {
      const remainingBudget = this.totalBudget - this.spentBudget;
      // TODO: handle negative budget more correctly
      if (remainingBudget < 0) return [this.spentBudget, 0];
      return [this.spentBudget, remainingBudget];
    }
  }
})
</script>

<style lang="css" scoped>
</style>
