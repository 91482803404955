<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-4" v-for="project in $store.state.customerData.projects" :key="project.id">
        <KpiBarChart :project="project" />
      </div>
    </div>
  </div>
</template>

<script>
import KpiBarChart from "@/components/KpiBarChart";

import { useRoute } from "vue-router";

export default {
  components: {
    KpiBarChart
  },
  computed: {
    id() {
      return useRoute().params.customerid
    }
  },
  async beforeMount() {
    try {
      // TODO: evaluate if this can be moved to app to guarantee data on each page
      await this.$store.dispatch("fetchCustomerProjects", useRoute().params.customerid);
    } catch(err) {
      console.log(err);
    }
  },
};
</script>
